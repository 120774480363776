import { useCallback } from 'react'
import type { BoundingBoxType, TokenType } from '@vatom/sdk/core'
import { RegionType } from '@vatom/sdk/core'

// import { BVatomTokenType } from '@vatom/BVatom/plugin'
import logger from '../logger'
import { useSDK } from '../store'

import { useConfig } from './useConfig'

export const useFilterBlackList = () => {
  const config = useConfig()

  return useCallback(
    (tokens: TokenType[]) => {
      const tkns = tokens
        .filter(t => {
          if (t.type === 'vatom') {
            // TODO FIX circular dependency
            // const v = t as BVatomTokenType
            const v = t as any
            return !config?.features?.inventory?.tv_blacklist.includes(
              v.properties.template_variation
            )
          }
          return true
        })
        .filter(
          t =>
            !config?.features?.inventory?.object_definition_blacklist.includes(
              t.studioInfo?.objectDefinitionId || ''
            )
        )
      return tkns
    },
    [
      config?.features?.inventory?.tv_blacklist,
      config?.features?.inventory?.object_definition_blacklist
    ]
  )
}

export const useGeoInventory = (boundingBox: BoundingBoxType) => {
  logger.debug('useGeoInventory: start')
  const sdk = useSDK()
  const filterByBlackList = useFilterBlackList()
  const region = sdk.dataPool.region(RegionType.geopos, JSON.stringify(boundingBox))
  logger.debug('useGeoInventory: tokens length: ', region.tokens.length)

  return {
    isLoading: sdk.dataPool.isLoading,
    tokens: filterByBlackList(region.tokens)
  }
}

let countRenders = 0

export const useInventory = () => {
  const sdk = useSDK()

  countRenders++
  logger.debug('[useInventory] RENDER useInventory', countRenders)

  return sdk.dataPool.region(RegionType.inventory)
}

export const useInventoryDeferred = ({ defer }: { defer?: boolean } = {}) => {
  const sdk = useSDK()

  if (defer) {
    return null
  }
  const region = sdk.dataPool.region(RegionType.inventory)
  return {
    bVatomRegion: region,
    isLoading: region.isLoading,
    filteredTokens: region.filteredTokens,
    sortedTokens: region.sortedTokens,
    walletTokens: region.walletTokens,
    reload: () => {
      region.reload()
    },
    fetchPage: async (args: unknown) => {
      await region.fetchPage(args)
    }
  }
}

export const useBusinessInventory = (businessId?: string) => {
  const region = useInventory()
  const filterByBlackList = useFilterBlackList()

  const tokens: TokenType[] = businessId
    ? region?.sortedTokens.filter(
        t => t.type === 'vatom' && t.studioInfo?.businessId === businessId
      ) ?? []
    : region?.sortedTokens ?? []

  return {
    isLoading: region?.isLoading ?? true,
    tokens: filterByBlackList(tokens)
  }
}

type TokenInventoryOptions = {
  contractAddress: string
  owner: string
}

export const useTokenInventory = (id: string, options?: TokenInventoryOptions) => {
  const sdk = useSDK()
  const filterByBlackList = useFilterBlackList()
  const region = sdk.dataPool.region(RegionType.inventory)
  const tokens = filterByBlackList(region.tokens)
  const token = options
    ? tokens.find(
        tk =>
          tk.id === id &&
          tk.owner === options.owner &&
          tk.contractAddress === options.contractAddress
      )
    : tokens.find(tk => tk.id === id)

  return {
    isLoading: region.isLoading,
    token
  }
}
