import type { PageConfig, scanner, WalletScreensConfig } from '.'

import type { AppConfig } from '@vatom/models'
import { AppConfigurationSchema } from '@vatom/models'
import { create } from 'zustand'

// export interface VatomConfig {
//   visibleTabs?: string[]
//   pageConfig?: PageConfig
//   baseUrl?: string
//   path?: string
//   language?: string
//   scanner?: scanner
//   mapStyle?: any
//   emptyStateImage?: string
//   emptyStateTitle?: string
//   emptyStateMessage?: string
//   screensConfig?: WalletScreensConfig

//   hideDrawer?: boolean
//   hideNavigation?: boolean
//   hideTokenActions?: boolean
//   disableArPickup?: boolean
//   disableNewTokenToast?: boolean

// }

export type ViewMessageHandler = {
  'vatom.view.close'?: unknown
  'viewer.view.close'?: unknown
}

export type ViewMessagesKey = keyof ViewMessageHandler

export type VatomWalletSdkStore = {
  // inventory was updated
  inventoryWasUpdated?: boolean
  setinventoryWasUpdated: (inventoryWasUpdated: boolean) => void
  // navigation is ready
  isNavigationReady?: boolean
  setNavigationReady: (navigationReady: boolean) => void
  // config
  config: {
    hideDrawer?: boolean | null
    hideNavigation?: boolean | null
    hideTokenActions?: boolean | null
    disableArPickup?: boolean | null
    disableNewTokenToast?: boolean | null
    language?: string
    systemThemeOverride?: 'light' | 'dark' | null | undefined
    visibleTabs: string[]
    pageConfig?: PageConfig
    baseUrl?: string
    path?: string
    mapStyle?: any
    emptyStateImage?: string
    emptyStateTitle?: string
    emptyStateMessage?: string
    screensConfig?: WalletScreensConfig
    scanner?: scanner
  }
  // is embedded
  isEmbedded: boolean
  // access token
  accessToken?: string
  setAccessToken: (accessToken: string) => void
  // refresh token
  refreshToken?: string
  setRefreshToken: (refreshToken: string) => void
  // bussines id
  businessId?: string
  setBusinessId: (businessId: string) => void
  // POPUP_HOSTNAME
  popupHostName?: string
  setPopupHostName: (popupHostName: string) => void
  // embeddedType
  embeddedType?: string
  setEmbeddedType: (embeddedType: string) => void
  walletConfig: AppConfig
  // view messages
  viewMessageHandlers: ViewMessageHandler
  setViewMessagesHandlers: (viewHandlerKey: ViewMessagesKey, callback: unknown) => void
}

export type config = {
  hideDrawer: boolean
  hideNavigation: boolean
  hideTokenActions: boolean
  disableArPickup: boolean
  disableNewTokenToast: boolean
  language?: string
  visibleTabs: string[]
  scanner?: scanner
}

export const initialSate = {
  isEmbedded: false,
  viewMessages: [],
  viewMessageHandlers: {},
  config: {
    hideDrawer: true,
    hideNavigation: true,
    hideTokenActions: false,
    disableArPickup: true,
    disableNewTokenToast: true,
    visibleTabs: [],

    scanner: {
      enabled: false
    }
  }
}

export const useVatomWalletSdkStore = create<VatomWalletSdkStore>((set, get) => ({
  ...initialSate,
  walletConfig: AppConfigurationSchema.parse({}),
  setinventoryWasUpdated: (inventoryWasUpdated: boolean) => {
    set({ inventoryWasUpdated })
    setTimeout(() => {
      set({ inventoryWasUpdated: !inventoryWasUpdated })
    }, 10000)
  },
  setNavigationReady: (isNavigationReady: boolean) => {
    set({ isNavigationReady })
  },
  setAccessToken: (accessToken: string) => {
    set({ accessToken })
  },
  setRefreshToken: (refreshToken: string) => {
    set({ refreshToken })
  },
  setBusinessId: (businessId: string) => {
    set({ businessId })
  },
  setPopupHostName: (popupHostName: string) => {
    set({ popupHostName })
  },
  setEmbeddedType: (embeddedType: string) => {
    set({ embeddedType })
  },
  setViewMessagesHandlers: (viewHandlerKey: ViewMessagesKey, callback: unknown) => {
    const { viewMessageHandlers } = get()

    set({
      viewMessageHandlers: {
        ...viewMessageHandlers,
        [viewHandlerKey]: callback
      }
    })
  }
}))
