import type { Subscription } from 'expo-modules-core'
import { EventEmitter, NativeModulesProxy } from 'expo-modules-core'

import { ChangeEventPayload, Web3WalletViewProps } from './src/Web3Wallet.types'
// Import the native module. On web, it will be resolved to Web3Wallet.web.ts
// and on native platforms to Web3Wallet.ts
import Web3WalletModule from './src/Web3WalletModule'
import Web3WalletView from './src/Web3WalletView'

// Get the native constant value.
export const PI = Web3WalletModule.PI

export type WalletDetails = {
  address: string
  privateKey: string
  words: string
}

export type WalletBackups = {
  /**
   * a unique key that will be used for the actual retrieval of the wallet's seed phrase
   *
   */
  acct: string
  /**
   * the actual wallet address stored as a label
   *
   */
  labl: string
}

export function hello(): WalletDetails {
  return Web3WalletModule.hello()
}

export async function setValueAsync() {
  return await Web3WalletModule.setValueAsync()
}

export async function getWallet(seeds?: string): Promise<WalletDetails> {
  throw new Error('not supported')
}

export async function encryptAndStore(key: string, seeds: string): Promise<boolean> {
  throw new Error('not supported')
}

export async function retrieveAndDecrypt(key: string): Promise<string> {
  throw new Error('not supported')
}

export async function retrieveAddresses(): Promise<string[]> {
  throw new Error('not supported')
}

export async function signPersonalMessage(seeds: string, nonce: string): Promise<string> {
  throw new Error('not supported')
}

export async function backupWallet(
  seedPhrase: string,
  address: string,
  password: string
): Promise<boolean> {
  throw new Error('not supported')
}

export async function restoreWallet(key: string, password: string): Promise<string> {
  throw new Error('not supported')
}

export async function retrieveWalletBackups(): Promise<WalletBackups[]> {
  throw new Error('not supported')
}

export async function deleteBackup(key: string): Promise<boolean> {
  throw new Error('not supported')
}

export async function deleteKey(key: string): Promise<boolean> {
  throw new Error('not supported')
}

const emitter = new EventEmitter(Web3WalletModule ?? NativeModulesProxy.Web3Wallet)

export function addChangeListener(listener: (event: ChangeEventPayload) => void): Subscription {
  return emitter.addListener<ChangeEventPayload>('onChange', listener)
}

export { ChangeEventPayload, Web3WalletView, Web3WalletViewProps }
