import type { PathConfigMap } from '@react-navigation/native'
import { getStateFromPath } from '@react-navigation/native'
import type { AppStackParamList } from '@vatom/wallet-routes'
import { AppRoutes, TabRoutes } from '@vatom/wallet-routes'
import { pathToRegexp } from 'path-to-regexp'

// eslint-disable-next-line @typescript-eslint/ban-types
type Options<ParamList extends {}> = {
  initialRouteName?: string
  screens: PathConfigMap<ParamList>
}

export function getInitialNavigationState(
  path: string,
  options: Options<AppStackParamList> | undefined,
  isAuthed: boolean
) {
  // NOTE: Platform.OS === 'web' needs to load the same AppRoutes.connect screen to catch the auth callback
  const newPath = path === '/callback' ? '/connect' : path
  // Get default state the deeplink would have without us interfering
  const state = getStateFromPath(newPath, options)

  const link = new URL('https://wallet.vatom.com' + path)

  const urlSearchParams = new URLSearchParams(link.search)
  urlSearchParams.delete('state')
  const otp = urlSearchParams.get('otp')
  const identityValue = urlSearchParams.get('iden-value')
  const loginType = urlSearchParams.get('iden-type')
  // this was not being get from the without 'https://wallet.vatom.com', idk why
  const uid = urlSearchParams.get('uid')

  if (otp && identityValue && loginType && !isAuthed) {
    // this not working with path directly
    const regexClaim = pathToRegexp('/claim/:claimId')
    const claimId = regexClaim.exec(link.pathname)?.pop()
    const regexToken = pathToRegexp('/token/:tokenId')
    const tokenId = regexToken.exec(link.pathname)?.pop()

    const params: {
      otp: string
      'iden-type': string
      'iden-value': string
      claimId?: string
      uid?: string
    } = {
      otp,
      'iden-type': loginType,
      'iden-value': identityValue
    }

    if (claimId) {
      params['claimId'] = claimId
    } else if (tokenId) {
      params['claimId'] = tokenId
    }

    if (uid) {
      params['uid'] = uid
    }

    return {
      routes: [
        {
          name: AppRoutes.Claim,
          params
        }
      ]
    }
  }

  const isCommunityHome = pathToRegexp('/b/:business/c/:community').test(path)
  // TODO: Replace .incliudes and other weak checks with pathToRegexp
  if (
    !isCommunityHome &&
    state &&
    path.startsWith('/b/') &&
    (path.includes('/space/') || path.includes('/c/'))
  ) {
    return {
      routes: communitiesStack(state.routes[1])
    }
  }

  if (path.startsWith('/b/')) {
    const businessOptions = options
    if (businessOptions?.initialRouteName) {
      businessOptions.initialRouteName = AppRoutes.BusinessProxy
    }
    const businessState = getStateFromPath(path, businessOptions)
    const customState = customStack(state?.routes[1] ?? '')

    return customState
      ? {
          routes: customState
        }
      : businessState
  }

  if (path === '/') {
    return {
      routes: [
        {
          name: AppRoutes.home,
          state: {
            routes: [
              {
                name: TabRoutes.Wallet
              }
            ]
          }
        }
      ]
    }
  }

  return state
}

const customStack = (currentRoute: any) => {
  const params = currentRoute.params
  const stack = {
    [AppRoutes.profileOtherUser_Business]: [
      {
        name: AppRoutes.BusinessProxy,
        params: {
          business: params?.business
        },
        state: {
          routes: [
            {
              name: TabRoutes.Connect
            }
          ]
        }
      },
      currentRoute
    ]
  }
  // @ts-expect-error this is a weird one, not really sure how to fix. Somehow we need to check if the .name is 1 of the key of stack
  return stack[currentRoute.name]
}

const communitiesStack = (currentRoute: any) => {
  const params = currentRoute.params
  const createStack = (withCommunities: boolean) => {
    const keyName = withCommunities ? 'Communities' : ''
    return {
      [AppRoutes[`${keyName}Room`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomMembers`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomNewMessage`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomThread`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        currentRoute
      ],
      [AppRoutes[`${keyName}RoomReply`]]: [
        {
          name: AppRoutes.BusinessProxy,
          params: {
            business: params?.business
          },
          state: {
            routes: [
              {
                name: TabRoutes.Connect
              }
            ]
          }
        },
        {
          name: AppRoutes[`${keyName}Room`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId
          }
        },
        {
          name: AppRoutes[`${keyName}RoomThread`],
          params: {
            business: params?.business,
            spaceId: params?.spaceId,
            messageId: params?.messageId
          }
        },
        currentRoute
      ]
    }
  }
  const stack = {
    ...createStack(true),
    ...createStack(false)
  }

  return stack[currentRoute.name]
}
