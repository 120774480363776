import React from 'react'

import theme from '../theme'

import type { LoaderProps } from './Loader'

import './loader.css'

export const Loader = ({ size = 50, color = '#FC500E' }: LoaderProps) => {
  return (
    <div
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        display: 'flex'
      }}
    >
      <svg
        className="circular-loader"
        style={{
          width: `${size}px`,
          height: `${size}px`
        }}
        viewBox="25 25 50 50"
      >
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="#FC500E"
          strokeWidth="2"
        />
      </svg>
    </div>
  )
}
